import React from "react";
import styled from "styled-components";

import { Container, FlexGrid, H2, H3, P } from "@util/standard";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { moneyFormat } from "@util/helper";
import { Shopify } from "@state/types";
import TextButton from "@shared/textButton";
import { Image } from "@global";
import { useAllProductDeclaredWeightQuery } from "@query";
import { useCheckScreenWidth } from "@util/hooks";
import { decode } from "shopify-gid";
interface Props {
  shopify: Shopify;
  orderState: number | null;
  setOrder: React.Dispatch<React.SetStateAction<number | null>>;
}

const HeaderContainer = styled(Container)`
  margin-bottom: 30px;
`;

const HeaderRow = styled(Container)`
  font-weight: bold;
  width: 100%;
  justify-content: stretch;
`;

const Row = styled(Container)`
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colorsRGB.merlin(0.3)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
`;

const Cell = styled(Container)`
  padding: 20px;
  align-items: center;
  &:first-of-type {
    width: 50%;
  }
  &:nth-of-type(2) {
    width: 20%;
    justify-content: center;
  }
  &:nth-of-type(3) {
    width: 10%;
    justify-content: center;
  }
  &:last-of-type {
    width: 20%;
    justify-content: flex-end;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 10px;
    &:first-of-type {
      width: 70%;
      flex-direction: column;
      align-items: flex-start;
    }
    &:last-of-type {
      width: 30%;
      justify-content: flex-end;
    }
  }
`;

const ImageContainer = styled(Container)`
  padding: 10px 20px;
  background: ${colors.imageBackground};
  margin: -20px 20px -20px -20px;
  img {
    height: 70px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 0 10px;
    padding: 5px 10px;
    img {
      height: 40px;
    }
  }
`;

const Strike = styled.s`
  display: inline-block;
  margin-left: 10px;
`;

const FooterRows = styled(Container)`
  width: 100%;
`;

const FooterRow = styled(Container)`
  width: 100%;
  &:last-of-type {
    font-weight: bold;
  }
  > div {
    padding: 10px 20px;
  }
  > div:first-of-type {
    width: 60%;
  }
  > div:nth-of-type(2) {
    width: 20%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  > div:nth-of-type(3) {
    width: 20%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    > div {
      padding: 10px 0;
    }
    > div:first-of-type {
      width: 50%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    > div:last-of-type {
      width: 50%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
`;

const Small = styled.small`
  color: ${colorsRGB.merlin(0.6)};
`;

const OrderStatus = styled(Container)`
  width: 100%;
  border: 1px solid ${colorsRGB.merlin(0.3)};
  border-radius: 4px;
  padding: 20px;
  flex-direction: column;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 10px;
  }
`;

const AddressContainer = styled(Container)`
  margin-top: 20px;
  p {
    margin: 0;
  }
`;

const Status = styled(Container)`
  text-transform: capitalize;
`;

const StyledLink = styled.a`
  color: ${colors.merlin};
  margin-left: 5px;
  text-decoration: underline;
  text-underline-offset: 2px;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

export default function Orders({ shopify, orderState, setOrder }: Props) {
  const order = shopify.customer?.orders?.edges?.find(
    obj => obj.node.orderNumber === orderState
  )?.node;

  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const notSmallScreen = isMobileWidth !== true && isTabletWidth !== true;
  const isSmallScreen = isMobileWidth === true || isTabletWidth === true;
  const allProducts = useAllProductDeclaredWeightQuery();
  const shopifyProductIds = order?.lineItems?.edges.map(lineItem => {
    return lineItem?.node?.variant?.product.id;
  });

  const filteredSanityProducts = allProducts?.nodes?.filter(product => {
    if (!product?.content?.shopify?.productId) return false;
    const matches = shopifyProductIds?.includes(
      product.content.shopify.productId.toString()
    );
    // return matches;
    if (matches === false) {
      return false;
    }
    if (!product?.content?.info?.declaredWeight) return false;
    return true;
  });
  const weightArr = filteredSanityProducts?.map(product => {
    if (!product?.content?.info?.declaredWeight) return;
    return {
      weight: product?.content?.info?.declaredWeight,
      productId: product?.content?.shopify?.productId?.toString(),
    };
  });

  if (!order) {
    return (
      <P color="errorRed">
        Failed to display order. Please contact us for support.
      </P>
    );
  }

  let cartTotalBeforeDiscount: number = 0;
  const discounts = order.discountApplications.edges.map(obj => {
    if (obj.node?.title) {
      return obj.node?.title;
    }
    if (obj.node?.code) {
      return obj.node?.code;
    }
  });

  const TrackingNumbers = () => {
    if (!order?.successfulFulfillments) return null;
    if (order.successfulFulfillments.length < 1) return null;

    const arr = order?.successfulFulfillments.filter(tracking => {
      const trackingInfo = tracking?.trackingInfo[0];

      if (trackingInfo?.number || trackingInfo?.url) return true;
      return false;
    });

    arr.length < 1 && null;

    return (
      <Container>
        Tracking Number:{" "}
        {arr.map((tracking, i) => {
          const trackingInfo = tracking.trackingInfo[0];
          if (trackingInfo.url) {
            return (
              <span key={i}>
                <StyledLink href={trackingInfo.url} target="_blank">
                  {trackingInfo.number ?? "Track here"}
                </StyledLink>
                {i !== arr.length - 1 && ", "}
              </span>
            );
          }
          return (
            <span key={i}>
              {trackingInfo.number}
              {i !== arr.length - 1 && ","}
            </span>
          );
        })}
      </Container>
    );
  };

  return (
    <Container flexDirection="column" width="100%">
      <HeaderContainer justifyContent="space-between">
        <H2 margin="10px 20px 10px 0">Order {order.name}</H2>
        <TextButton linkText="Back" onClick={() => setOrder(null)} />
      </HeaderContainer>
      <TrackingNumbers />
      <Container flexDirection="column" width="100%" alignItems="flex-end">
        <HeaderRow>
          <Cell>Product</Cell>
          {notSmallScreen && (
            <>
              <Cell>Price</Cell>
              <Cell>Quantity</Cell>
            </>
          )}
          <Cell>Total</Cell>
        </HeaderRow>
        {order.lineItems?.edges.map((line, i) => {
          const lineItem = line?.node;
          const weight = weightArr.find(
            obj => obj?.productId === decode(lineItem.variant?.product.id)?.id
          )?.weight;

          if (!lineItem) return;
          cartTotalBeforeDiscount += parseFloat(
            lineItem.discountedTotalPrice?.amount
          );
          return (
            <Row key={`${lineItem.title}-${i}`}>
              <Cell>
                {lineItem.variant?.image?.transformedSrc && (
                  <ImageContainer>
                    <Image
                      staticImage={lineItem.variant?.image?.transformedSrc}
                    />
                  </ImageContainer>
                )}
                <Container flexDirection="column">
                  <span>
                    {lineItem.title}
                    {weight && <span> ({weight})</span>}
                    <Small> SKU: {lineItem.variant?.sku}</Small>
                  </span>
                  {isSmallScreen && (
                    <Small>
                      Unit: {moneyFormat(lineItem.variant?.priceV2.amount)} x{" "}
                      {lineItem.quantity}
                    </Small>
                  )}
                </Container>
              </Cell>
              {notSmallScreen && (
                <>
                  <Cell>{moneyFormat(lineItem.variant?.priceV2.amount)}</Cell>
                  <Cell>{lineItem.quantity}</Cell>
                </>
              )}
              <Cell>
                {moneyFormat(lineItem.discountedTotalPrice?.amount)}
                {lineItem.discountedTotalPrice?.amount !==
                  lineItem.originalTotalPrice?.amount && (
                  <Strike>
                    {moneyFormat(lineItem.originalTotalPrice?.amount)}
                  </Strike>
                )}
              </Cell>
            </Row>
          );
        })}
        <FooterRows flexDirection="column">
          {cartTotalBeforeDiscount > 0 && (
            <FooterRow>
              {notSmallScreen && <Cell></Cell>}
              <Cell>
                Discount <Small>({discounts.join(", ")})</Small>
              </Cell>
              <Cell>
                -
                {moneyFormat(
                  cartTotalBeforeDiscount -
                    parseInt(order.subtotalPriceV2?.amount)
                )}
              </Cell>
            </FooterRow>
          )}
          <FooterRow>
            {notSmallScreen && <Cell></Cell>}
            <Cell>Subtotal</Cell>
            <Cell>{moneyFormat(order.subtotalPriceV2?.amount)}</Cell>
          </FooterRow>
          {parseFloat(order.totalShippingPriceV2?.amount) > 0 && (
            <FooterRow>
              {notSmallScreen && <Cell></Cell>}
              <Cell>Shipping</Cell>
              <Cell>{moneyFormat(order.totalShippingPriceV2?.amount)}</Cell>
            </FooterRow>
          )}
          {parseFloat(order.totalTaxV2?.amount) > 0 && (
            <FooterRow>
              {notSmallScreen && <Cell></Cell>}
              <Cell>Tax</Cell>
              <Cell>{moneyFormat(order.totalTaxV2?.amount)}</Cell>
            </FooterRow>
          )}
          <FooterRow>
            {notSmallScreen && <Cell></Cell>}
            <Cell>Total</Cell>
            <Cell>{moneyFormat(order.totalPriceV2?.amount)}</Cell>
          </FooterRow>
        </FooterRows>
      </Container>

      <FlexGrid
        gridMargin="35px"
        margin="50px 0 0"
        tabletMargin="20px 0 0"
        alignItems="flex-start"
      >
        {(order.fulfillmentStatus || order.shippingAddress) && (
          <Container width={isSmallScreen ? "100%" : "50%"}>
            <OrderStatus>
              <H3>Shipping Address</H3>
              <Status>
                <strong>Fulfillment Status:</strong>
                {order.fulfillmentStatus === "UNFULFILLED"
                  ? "To be packed"
                  : order.fulfillmentStatus === "FULFILLED"
                  ? "Dispatched"
                  : order.fulfillmentStatus === "PARTIALLY_FULFILLED" &&
                    "Partially dispatched"}
              </Status>
              {order.shippingAddress && (
                <AddressContainer flexDirection="column">
                  {(order.shippingAddress.firstName ||
                    order.shippingAddress.lastName) && (
                    <p>
                      {order.shippingAddress.firstName}{" "}
                      {order.shippingAddress.lastName}
                    </p>
                  )}
                  {order.shippingAddress.company && (
                    <p>{order.shippingAddress.company}</p>
                  )}
                  {order.shippingAddress.address1 && (
                    <p>{order.shippingAddress.address1}</p>
                  )}
                  {order.shippingAddress.address2 && (
                    <p>{order.shippingAddress.address2}</p>
                  )}
                  {order.shippingAddress.city && (
                    <p>{order.shippingAddress.city}</p>
                  )}
                  {order.shippingAddress.province &&
                    order.shippingAddress.province !==
                      order.shippingAddress.city && (
                      <p>{order.shippingAddress.province}</p>
                    )}
                  {order.shippingAddress.zip && (
                    <p>{order.shippingAddress.zip}</p>
                  )}
                  {order.shippingAddress.country && (
                    <p>{order.shippingAddress.country}</p>
                  )}
                  {order.shippingAddress.phone && (
                    <p>Phone: {order.shippingAddress.phone}</p>
                  )}
                </AddressContainer>
              )}
            </OrderStatus>
          </Container>
        )}
        {order.financialStatus && (
          <Container width={isSmallScreen ? "100%" : "50%"}>
            <OrderStatus>
              <H3>Billing Status</H3>
              <Status>
                <strong>Payment Status:</strong>
                {order.financialStatus?.toLowerCase()}
              </Status>
            </OrderStatus>
          </Container>
        )}
      </FlexGrid>
    </Container>
  );
}
